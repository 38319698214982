export interface TextField {
  component: 'text-field'
  id: string
  name: string
  placeholder: string
  is_email_input_type: boolean
  is_required: boolean
  validation_error: string
  exclude_field_in_tracking: boolean
}

export function normalizeTextField(field: TextField) {
  return {
    component: field.component,
    id: field.id,
    name: field.name,
    placeholder: field.placeholder,
    isEmailInputType: field.is_email_input_type,
    isRequired: field.is_required,
    validationError: field.validation_error,
    excludeFieldInTracking: field.exclude_field_in_tracking,
  }
}
