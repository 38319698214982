<script lang="ts" setup>
import type { TextField } from '~/types/storyblok/shared-content/text-field'
import { debounce } from 'lib/helpers/debounce'
import { normalizeTextField } from '~/types/storyblok/shared-content/text-field'

interface Props {
  field: TextField
  state: {
    value: string
    isError: boolean
  }
  bgColor?: string
}

const props = withDefaults(defineProps<Props>(), {
  bgColor: 'white',
})
const normalizedField = normalizeTextField(props.field)
const inputType = computed(() => normalizedField.isEmailInputType ? 'email' : 'text')
const { bgInvertedColor } = useBackgroundInvertedColor(props.bgColor ?? 'white')

const { placeholderColor, errorColor } = useFormColors(props.bgColor ?? 'white')
const { isEmailFieldAndNotValid, isRequiredFieldAndEmpty } = useFormFieldValidation()

const onInput = debounce(() => {
  if (inputType.value === 'email') {
    if (isEmailFieldAndNotValid(props.state.value, normalizedField.isRequired))
      props.state.isError = true
    else props.state.isError = false
  }
  else if (isRequiredFieldAndEmpty(props.state.value, normalizedField.isRequired)) {
    props.state.isError = true
  }
  else {
    props.state.isError = false
  }
}, 500)
const ariaLabel = computed(() => props.state.isError ? `${normalizedField.id} ${normalizedField.validationError}` : normalizedField.id)
const input = ref<HTMLInputElement | null>(null)

function focusField() {
  input?.value?.focus()
}

defineExpose({
  focusField,
})
</script>

<template>
  <div class="container">
    <label :for="normalizedField.id">{{ props.field.name }}</label>
    <input
      :id="normalizedField.id"
      ref="input"
      v-model="state.value"
      :type="inputType"
      :name="normalizedField.id"
      :placeholder="normalizedField.placeholder"
      :aria-invalid="props.state.isError"
      :aria-label="ariaLabel"
      @input="onInput"
    >
    <ErrorMessage
      v-show="props.state.isError"
      :color="errorColor"
      :text="normalizedField.validationError"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

.container {
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    width: 100%;
}

input {
    border: 1px solid v-bind(bgInvertedColor);
    border-radius: 2px;
    padding: 1.2rem;
    height: 4rem;
    background-color: transparent;

    &:focus {
        border: 1px solid transparent;
        outline-offset: -1px;
        outline: 2px solid v-bind(bgInvertedColor);
    }

    &::placeholder {
        background-color: transparent;
        color: v-bind(placeholderColor);
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 3rem v-bind(bgColor) inset;
        -webkit-text-fill-color: v-bind(bgInvertedColor);
    }

}
</style>
