export function useFormColors(bgColor: string) {
  const { bgInvertedColor } = useBackgroundInvertedColor(bgColor)

  const selectOptionLabelColor = computed(() => {
    return bgInvertedColor.value === 'var(--black)' ? 'var(--white)' : 'var(--black)'
  })

  const selectedOptionBgColor = computed(() => {
    let selectedOptionBgColor
    if (bgColor === '#00B852') {
      selectedOptionBgColor = 'var(--pink)'
    }
    else {
      selectedOptionBgColor = 'var(--green)'
    }
    return selectedOptionBgColor
  })

  const hoveredOptionBgColor = computed(() => {
    let hoveredOptionBgColor
    if (bgColor === '#FAE800') {
      hoveredOptionBgColor = 'var(--pink)'
    }
    else {
      hoveredOptionBgColor = 'var(--yellow-hover)'
    }
    return hoveredOptionBgColor
  })

  const errorColor = computed(() => {
    return bgColor === 'white' ? '#e03100' : bgInvertedColor.value
  })

  const placeholderColor = computed(() => {
    let placeholderColor
    if (bgColor === 'white' || bgColor === '#FAE800') {
      placeholderColor = 'var(--dark-hover)'
    }
    else if (bgColor === '#FE96B5' || bgColor === '#FF8114' || bgColor === '#00B852') {
      placeholderColor = '#333333'
    }
    else {
      placeholderColor = bgInvertedColor.value
    }
    return placeholderColor
  })

  const buttonLabelColor = computed(() => {
    return bgInvertedColor.value === 'var(--black)' ? 'var(--white)' : 'var(--black)'
  })

  const buttonHoverColor = computed(() => {
    return bgInvertedColor.value === 'var(--black)' ? 'var(--dark-hover)' : 'var(--gray)'
  })
  return {
    selectOptionLabelColor,
    selectedOptionBgColor,
    hoveredOptionBgColor,
    errorColor,
    placeholderColor,
    buttonLabelColor,
    buttonHoverColor,
  }
}
